import React from "react";

/**
 *
 * @param props = {header, body (can be a text array [], or a Component), footer buttons (each button should include an "action" function)}
 * @returns {JSX.Element}
 *
 */
export const ModalDialog = (props) => {
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '11',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        cursor: 'default'
    }

    const getBodyClass = () => {
        if (props.leftAlignBody) {
            if (props.removeBodyPadding) {
                return 'modal-dialog-body-no-padding'
            }

            return 'modal-dialog-body-less-padding'
        }

        return 'center modal-dialog-body'
    }

    return (
        <div style={popUpStyle}>
            <div id={'quiz-publish-alert'} className={props.useWideVersion ? 'modal-dialog-wide' : 'modal-dialog'}>
                <div className='modal-dialog-header' style={{backgroundColor: props.color ? props.color : null}}>
                    {props.imageIcon ? <img src={props.imageIcon} alt={props.header.toString()} style={{margin: '5px'}}/> : null}
                    {props.header.toString().toUpperCase()}
                </div>
                {
                    (() => {
                            if (Array.isArray(props.body)) {
                                return (
                                    <div id={props.id ? props.id : null}
                                        className={getBodyClass()}>
                                        {
                                            props.body.map((line, index) => (
                                                () => {
                                                    return (
                                                        <div className='modal-dialog-body-line' key={index}>
                                                            {line}
                                                        </div>
                                                    )
                                                })()
                                            )
                                        }
                                    </div>
                                )
                            }

                            // props.body is not an array
                            return (
                                <div
                                    className={getBodyClass()}>
                                    {props.body}
                                </div>
                            )
                        }
                    )()
                }

                <div className='modal-dialog-footer' style={{background: props.buttonBackground ? props.buttonBackground : null, color: props.textColor ? props.textColor : null}}>
                    {
                        props.buttons.map((button, index) => (
                            () => {
                                return (
                                    (() => {
                                            if (props.buttons.length === 1)
                                                return (
                                                    <div key={index}
                                                         className='model-dialog-full-width-footer-button'
                                                         onClick={button.action}
                                                    >
                                                        <label className='modal-dialog-footer-text'
                                                               key={index}
                                                        >
                                                            {button.text}
                                                        </label>
                                                    </div>
                                                )

                                            if (props.buttons.length > 1)
                                                return (
                                                    <div id={'button-text'} key={index}
                                                         className={`modal-dialog-footer-button ${index === 0 ? 'modal-dialog-footer-left-button' : null}`}
                                                         onClick={button.action}
                                                    >
                                                        <label className='modal-dialog-footer-text'
                                                               style={button.buttonTextColor ? {color: button.buttonTextColor} : null}
                                                               key={index}
                                                        >
                                                            {button.text}
                                                        </label>
                                                    </div>
                                                )
                                        }
                                    ))()
                            })()
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ModalDialog
