const initialState = {
    searchText: '',
    showSearchInput: false,
    showFilter: false,
    showTable: false,
    answerData: null,
    responseCount: null,
    showExport: false,
    showSummary: true,
    showResponses: false,
    summaryDataSet: null,
    showIndividual: false,
    isRevoked: true,
    isSheetConnected: false,
    formResponsesData: null,
    questionFilterObject: [],
    isShowPublishAlert: false,
    quizSourceIsTabular: null,
    questionTitle: null,
    showChooseAccountPopUp: false,
    showGoogleSheetPopUp: false,
    googleSheetsData: [],
    googleSheetId: "",
    acknowledgmentMessage: "",
    showAcknowledgement: false,
    currentQuestionIndex: null,
    showQuestionScreen: false,
    multiSelectResponsesArray: [],
    individualResponseNumber: null,
    originalFormRespondents: null,
    showSheetDisconnectedPopup: false,
    originalSummaryDataSet: null,
    originalQuizAnswers: null
}

export const RESPONSES_ACTION_TYPE = {
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_SHOW_SEARCH_INPUT: 'SET_SHOW_SEARCH_INPUT',
    SET_SHOW_FILTER: 'SET_SHOW_FILTER',
    SET_SHOW_TABLE: 'SET_SHOW_TABLE',
    SET_ANSWER_DATA: 'SET_ANSWER_DATA',
    SET_SHOW_EXPORT: 'SET_SHOW_EXPORT',
    SET_SHOW_SUMMARY: 'SET_SHOW_SUMMARY',
    SUMMARY_DATA_SET: 'SUMMARY_DATA_SET',
    SET_RESPONSE_COUNT: 'SET_RESPONSE_COUNT',
    SET_SHOW_RESPONSES: 'SET_SHOW_RESPONSES',
    SET_SHOW_INDIVIDUAL: 'SET_SHOW_INDIVIDUAL',
    SET_IS_REVOKED: "SET_IS_REVOKED",
    SET_IS_SHEET_CONNECTED: "SET_IS_SHEET_CONNECTED",
    SET_FORM_RESPONSES_DATA: 'SET_FORM_RESPONSES_DATA',
    SET_QUESTION_TITLE: 'SET_QUESTION_TITLE',
    SET_SHOW_ACKNOWLEDGEMENT: 'SET_SHOW_ACKNOWLEDGEMENT',
    SET_GOOGLE_SHEETS_DATA: 'SET_GOOGLE_SHEETS_DATA',
    SET_GOOGLE_SHEET_ID: 'SET_GOOGLE_SHEET_ID',
    SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
    SET_SHOW_QUESTION_SCREEN: 'SET_SHOW_QUESTION_SCREEN',
    SET_IS_SHOW_PUBLISH_ALERT: 'SET_IS_SHOW_PUBLISH_ALERT',
    SET_QUIZ_SOURCE_IS_TABULAR: 'SET_QUIZ_SOURCE_IS_TABULAR',
    SET_QUESTION_FILTER_OBJECT: "SET_QUESTION_FILTER_OBJECT",
    SET_ACKNOWLEDGEMENT_MESSAGE: "SET_ACKNOWLEDGEMENT_MESSAGE",
    SET_SHOW_GOOGLE_SHEET_POP_UP: 'SET_SHOW_GOOGLE_SHEET_POP_UP',
    SET_SHEET_DISCONNECTED_POP_UP: 'SET_SHEET_DISCONNECTED_POP_UP',
    SET_SHOW_CHOOSE_ACCOUNT_POP_UP: 'SET_SHOW_CHOOSE_ACCOUNT_POP_UP',
    SET_INDIVIDUAL_RESPONSE_NUMBER: 'SET_INDIVIDUAL_RESPONSE_NUMBER',
    SET_ORIGINAL_FORM_RESPONDENTS: 'SET_ORIGINAL_FORM_RESPONDENTS',
    SET_MULTI_SELECT_RESPONSES_ARRAY: 'SET_MULTI_SELECT_RESPONSES_ARRAY',
    SET_ORIGINAL_SUMMARY_DATA_SET:'SET_ORIGINAL_SUMMARY_DATA_SET',
    SET_ORIGINAL_QUIZ_ANSWERS:'SET_ORIGINAL_QUIZ_ANSWERS'
}

const responsesReducer = (state = initialState, action) => {
    switch (action.type) {
        case (RESPONSES_ACTION_TYPE.SET_SEARCH_TEXT):
            return {
                ...state,
                searchText: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_SEARCH_INPUT):
            return {
                ...state,
                showSearchInput: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_IS_REVOKED):
            return {
                ...state,
                isRevoked: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED):
            return {
                ...state,
                isSheetConnected: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHEET_DISCONNECTED_POP_UP):
            return {
                ...state,
                showSheetDisconnectedPopup: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_FILTER):
            return {
                ...state,
                showFilter: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA):
            return {
                ...state,
                formResponsesData: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS):
            return {
                ...state,
                originalFormRespondents: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_QUIZ_SOURCE_IS_TABULAR):
            return {
                ...state,
                quizSourceIsTabular: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET):
            return {
                ...state,
                summaryDataSet: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_QUESTION_FILTER_OBJECT):
            return {
                ...state,
                questionFilterObject: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT):
            return {
                ...state,
                responseCount: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT):
            return {
                ...state,
                showExport: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY):
            return {
                ...state,
                showSummary: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_RESPONSES):
            return {
                ...state,
                showResponses: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_ACKNOWLEDGEMENT_MESSAGE):
            return {
                ...state,
                acknowledgmentMessage: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL):
            return {
                ...state,
                showIndividual: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_QUESTION_TITLE):
            return {
                ...state,
                questionTitle: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_CURRENT_QUESTION_INDEX):
            return {
                ...state,
                currentQuestionIndex: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_QUESTION_SCREEN):
            return {
                ...state,
                showQuestionScreen: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_TABLE):
            return {
                ...state,
                showTable: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT):
            return {
                ...state,
                isShowPublishAlert: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_INDIVIDUAL_RESPONSE_NUMBER):
            return {
                ...state,
                individualResponseNumber: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_ANSWER_DATA):
            return {
                ...state,
                answerData: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT):
            return {
                ...state,
                showAcknowledgement: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_GOOGLE_SHEETS_DATA):
            return {
                ...state,
                googleSheetsData: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_CHOOSE_ACCOUNT_POP_UP):
            return {
                ...state,
                showChooseAccountPopUp: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_SHOW_GOOGLE_SHEET_POP_UP):
            return {
                ...state,
                showGoogleSheetPopUp: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_GOOGLE_SHEET_ID):
            return {
                ...state,
                googleSheetId: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY):
            return {
                ...state,
                multiSelectResponsesArray: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_ORIGINAL_SUMMARY_DATA_SET):
            return {
                ...state,
                originalSummaryDataSet: action.payload
            }
        case (RESPONSES_ACTION_TYPE.SET_ORIGINAL_QUIZ_ANSWERS):
            return {
                ...state,
                originalQuizAnswers: action.payload
            }
        default:
            return state
    }
}

export default responsesReducer
