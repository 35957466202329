import axios from "axios";
import {getQuizzoryWebToken} from "../../app/utils/jwt";

export const QUIZ_BASE_URL = process.env.REACT_APP_ENV_QUIZ_SERVER_URL || "https://quizzory.in";
const quizzesListUrl = QUIZ_BASE_URL + '/v2/getquizzes';
const quizUrl = QUIZ_BASE_URL + '/quiz';
const quizAnswersUrl = QUIZ_BASE_URL + "/getanswers";
const REQUEST_METHOD = {
    POST: "POST",
    GET: "GET",
    DELETE: "DELETE",
    PUT: "PUT"
};

export const removeDeletedThemeQuiz = (imageUrl, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        theme_image_url: imageUrl
    })
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/removedeletedthemequiz", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const removeDeletedQuestionAttachmentQuiz = (imageUrl, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        quiz_image_url: imageUrl
    })
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/removedeletedquizquestionsattachment", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getSharedQuizzes = ( successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + '/v2/quiz/collaboration/getsharedquizzes', REQUEST_METHOD.POST, {}, res => successFunction(res),
        err => failureFunction(err))
}

export const getQuizCollaborator = (id, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({});
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/v2/quiz/collaborators/get/" + id, REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const removeQuizCollaborator = ( quizId, collaboratorId, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "quiz_id": quizId,
        "collaborator_id": collaboratorId
    });

    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/v2/quiz/collaborator/remove", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const addQuizCollaborator = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/v2/quiz/collaborator/add", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const deleteMultipleAwsFilesQuiz = (deleteData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        delete_data: deleteData
    })
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/removeawsfiles", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const deleteMultipleAwsFilesQuizByQuestions = (deleteData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        delete_data: deleteData
    })
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/removefilesbyquestion", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const deleteMultipleAwsFilesQuizByQuizzes = (deleteData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        quiz_ids: deleteData
    })
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/removefilesbyquizzes", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const downloadAwsFilesByQuizRespondent = (downloadData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        download_data: downloadData
    })
    makeAuthorizedServerRequestForFiles(QUIZ_BASE_URL + "/downloadfiles", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const downloadAwsFilesByQuizQuestions = (downloadData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        download_data: downloadData
    })
    makeAuthorizedServerRequestForFiles(QUIZ_BASE_URL + "/downloadfilesbyquizquestions", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const downloadAwsFilesByQuizzes = (downloadData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        quiz_ids: downloadData
    })
    makeAuthorizedServerRequestForFiles(QUIZ_BASE_URL + "/downloadfilesbyquiz", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const changeQuizCollaboratorRole = (quizId, collaboratorId, role, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "quiz_id": quizId,
        "collaborator_id": collaboratorId,
        "role": role
    });
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/v2/quiz/collaborator/changerole", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const leaveQuizCollaboration = (quizId, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "quiz_id": quizId,
    });
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/v2/quiz/collaboration/leave", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getSearchQuizQuestionData = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + "/searchquizquestiondata", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getQuizById = (quizId, successFunction, failureFunction) => {
    if (quizId) {
        makeAuthorizedServerRequest(`${quizUrl}/${quizId}`, REQUEST_METHOD.POST, {}, res => successFunction(res),
            err => failureFunction(err))
    }
}
export const getQuizAnswers = (quizId, successFunction, failureFunction) => {
    if (quizId) {
        let data = JSON.stringify({
            form_id: quizId
        })
        makeAuthorizedServerRequest(quizAnswersUrl, REQUEST_METHOD.POST, data, res => successFunction(res),
            err => failureFunction(err))
    }
}
// to get all quizzes of the user.
export const getQuizzesListService = ( successFunction, failureFunction) => {
    makeAuthorizedServerRequest(quizzesListUrl, REQUEST_METHOD.POST, {}, res => successFunction(res),
        err => failureFunction(err))
}
export const deleteQuizService = (formId, successFunction, failureFunction) => {
    let requestUrl = quizUrl + '/' + formId;

    makeAuthorizedServerRequest(requestUrl, REQUEST_METHOD.DELETE, {}, res => {
        successFunction(res);
    }, err => {
        failureFunction(err);
    });
}

export const deleteManyQuizzes = (userId, formIds, successFunction, failureFunction) => {
    let requestUrl = QUIZ_BASE_URL + '/v2/quiz/deletemany';
    const requestBody = JSON.stringify({
        quizAppData: {
            user_id: userId,
            form_ids: formIds
        }
    })
    makeAuthorizedServerRequest(requestUrl, REQUEST_METHOD.DELETE, requestBody, res => {
        successFunction(res);
    }, err => {
        failureFunction(err);
    });
}


export const updateQuizStatusService = (formId, status, successFunction, failureFunction) => {
    const data = JSON.stringify({
        quizAppData: {
            form_id: formId,
            status: status
        }
    });
    makeAuthorizedServerRequest(quizUrl, REQUEST_METHOD.PUT, data, res => successFunction(res),
        err => failureFunction(err))
}
// to delete one answer of a quiz.
export const deleteQuizAnswerService = (formId, responseId, successFunction, failureFunction) => {
    let requestUrl = QUIZ_BASE_URL + '/answer'
    let data = JSON.stringify({
        form_id: formId,
        response_id: responseId
    });
    makeAuthorizedServerRequest(requestUrl, REQUEST_METHOD.DELETE, data, res => successFunction(res),
        err => failureFunction(err))
}

export function deleteMultipleAnswersInQuiz(formId, responseIds, successFunction, failureFunction) {
    let requestBody = {
        quiz_id: formId,
        answer_ids: responseIds
    };
    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/v2/quiz/delete/answers`, REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction);
}

export const quizCustomMarkUpdateService = (data, successFunction, failureFunction) => {
    let body = {
        answer_id: data.answerId,
        question_id: data.questionId,
        marks: parseInt(data.markToUpdate),
        is_publish: data.isPublish,
        // user_id: data.userId,
        // submitterIsOwner: data.submitterIsOwner,
        quiz_id: data.quizId
    }
    makeAuthorizedServerRequest((QUIZ_BASE_URL + '/v1/marks'), REQUEST_METHOD.POST, body, successFunction, failureFunction);
}

export const getQuizNotifications = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + '/getnotifications', REQUEST_METHOD.POST, {}, successFunction, failureFunction)
}

export const updateQuizNotificationStatus = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + '/notified', REQUEST_METHOD.POST, {}, successFunction, failureFunction)
}

export const deleteQuizNotification = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + '/deletenotification', REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const clearAllQuizNotifications = ( successFunction, failureFunction) => {
    makeAuthorizedServerRequest(QUIZ_BASE_URL + '/clearallnotification', REQUEST_METHOD.DELETE, {}, successFunction, failureFunction)
}

export const createQuiz = (data, successFunction, failureFunction) => {
    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/quiz`, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}

export const updateQuiz = (quizId, data, successFunction, failureFunction) => {
    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/quiz/${quizId}`, REQUEST_METHOD.PUT, data, successFunction, failureFunction)
}

export const saveSettings = (quizId, settings, successFunction, failureFunction) => {
    let body = {
        quiz_id: quizId,
        setting: settings
    }

    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/v1/quiz/setting`, REQUEST_METHOD.POST, body, successFunction, failureFunction)
}
export const quizPublishService = (data, successFunction, failureFunction) => {
    let body = {
        quiz_id: data.quiz_id
    }
    makeAuthorizedServerRequest(QUIZ_BASE_URL + '/v1/publish', REQUEST_METHOD.POST, body, successFunction, failureFunction)
}

export function setQuizFavouriteStatus(quizId, dateFavoured, successEvent, failureEvent) {
    const requestBody = JSON.stringify({
        form_id: quizId,
        date_favoured: dateFavoured
    });
    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/v2/setquizfavourite`, REQUEST_METHOD.PUT, requestBody, successEvent, failureEvent);
}

export function setMultipleQuizFavourite(quizIds, date, successEvent, failureEvent) {
    const requestBody = JSON.stringify({
        quiz_ids: quizIds,
        date_favoured: date
    });
    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/v2/setmultiplequizzesfavourite`, REQUEST_METHOD.PUT, requestBody, successEvent, failureEvent);
}

const makeAuthorizedServerRequest = (requestURL, requestMethod, requestBody, successCallBackFunction, failureCallBackFunction, authToken) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: requestBody,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + (authToken ? authToken : getQuizzoryWebToken())
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log('error',err)
            failureCallBackFunction(err.response)
        })
}


export const saveQuizzoryUserData = (requestBody, successFunction, failureFunction) => {
    const newRequestBody = JSON.stringify({quizAppData: requestBody.surveyHeart});
    makeServerRequest(QUIZ_BASE_URL + '/v2/web/signup/temp', REQUEST_METHOD.POST, newRequestBody, res => successFunction(res),
        err => failureFunction(err))
}

export function getTotalStorageUsed( successFunction, failureFunction) {
    makeAuthorizedServerRequest(`${QUIZ_BASE_URL}/v2/gettotalstorageused`, REQUEST_METHOD.POST, {}, successFunction, failureFunction)
}

const makeServerRequest = (requestURL, requestMethod, data, successCallBackFunction, failureCallBackFunction) => {
    let requestParams = {
        method: requestMethod,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    };
    fetch(requestURL, requestParams)
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                failureCallBackFunction(response);
            }
        })
        .then(responseJSON => {
            if (responseJSON !== undefined) {
                successCallBackFunction(JSON.stringify(responseJSON));
            }
        })
        .catch(error => {
            console.log('callback failure',error);
            failureCallBackFunction(error);
            console.log(error);
        });
}

export const TYPE = {
    EXCEL: 'excel',
    CSV: 'csv',
    PDF: 'pdf'
}

/**
 *
 * @param fileType
 * @param formId
 * @param successFunction
 * @param failureFunction
 */
export function downloadAnswersAsFile(fileType, formId, successFunction, failureFunction) {
    const timeZoneOffset = new Date().getTimezoneOffset()
    const quizExportLinks = {
        xlsx: QUIZ_BASE_URL + '/v2/excel?format=xlsx&token=TVqKj82cg4rDcY8yqAdxIxPihrlWiBbBQCET4YjBZGWp9ZtLeR&id=' + formId + "&time_zone_offset=" + timeZoneOffset,
        pdf: QUIZ_BASE_URL + '/v2/pdf-export?token=O3swPiXql1YdzcQfaRzwCla7aR08UQSRgroPrU8nUdd5WIHI0G&id=' + formId + "&time_zone_offset=" + timeZoneOffset,
        csv: QUIZ_BASE_URL + '/v2/excel?format=csv&token=TVqKj82cg4rDcY8yqAdxIxPihrlWiBbBQCET4YjBZGWp9ZtLeR&id=' + formId + "&time_zone_offset=" + timeZoneOffset
    }
    const url = (fileType === TYPE.EXCEL) ? quizExportLinks.xlsx : (fileType === TYPE.CSV) ? quizExportLinks.csv : quizExportLinks.pdf;
    const options = {
        crossDomain: true,
        responseType: 'blob',
        headers: {
            'authorization': 'Bearer ' + getQuizzoryWebToken()
        }
    }
    axios.get(url, options)
        .then(successFunction)
        .catch(failureFunction)
}

export function getTotalStorageUsed_UnauthorizedRequest(requestBody, successFunction, failureFunction) {
    axios.post(`${QUIZ_BASE_URL}/v2/gettotalstorageusedunauthorizedrequest`, requestBody)
        .then(successFunction)
        .catch(failureFunction)
}

/*server call for files blob type*/
const makeAuthorizedServerRequestForFiles = (requestURL, requestMethod, requestBody, successCallBackFunction, failureCallBackFunction) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: requestBody,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + getQuizzoryWebToken()
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log(err)
            failureCallBackFunction(err.response)
        })
}
