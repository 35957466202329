import React from 'react'
import PropTypes from 'prop-types';
import img from '../../../public/images/loader.gif'

export default function Loader(props) {
    return (
        <div style={{
            width: props.width,
            height: props.height,
            position: 'absolute',
            backgroundColor: '#f5f5f580',
            zIndex: props.zIndex ? props.zIndex : '10',
            top: 0,
            left: props.left ? props.left : null,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src={img} alt="loader.gif"/>
        </div>
    )
}

Loader.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    left: PropTypes.string
}

Loader.defaultProps = {
    height: 'inherit',
    width: 'inherit'
}